<template>
  <div id="homeIntro1">
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12" md="6">
          <p v-if="this.$title" class="text-h4 font-weight-black title_intro">
            {{ $t("websiteTitle") }}
          </p>
          <p
            v-if="this.$subtitle"
            class="text-h5 font-weight-bold subtitle_intro"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 500px' : ''"
          >
            {{ $t("websiteSubtitle") }}
          </p>
          <div
            class="body-2 body_intro"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 350px' : ''"
          >
            <p v-html="$t('websiteIntro')"></p>
          </div>
          <div style="padding-top: 4%">
            <v-img
              max-height="44"
              max-width="200"
              :src="this.getIcon"
              :class="$vuetify.breakpoint.mdAndDown ? 'mx-auto' : ''"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-img :src="require('/public/porce_intro.jpg')"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeIntro",
  computed: {
    getIcon: function () {
      return this.$typeProject == "SELECT"
        ? require("/public/logo_porce_select.svg")
        : require("/public/logo_porce_partners.svg");
    },
  },
};
</script>

<style scoped>
p.title_intro {
  display: block;
  margin-bottom: 10px;
}
p.subtitle_intro {
  display: block;
  font-size: 0.9rem;
  line-height: 1.6rem;
  margin-bottom: 4%;
}
.body_intro {
  display: block;
  font-size: 0.85rem !important;
}

.body_intro br {
  margin-bottom: 16px;
}
</style>
