<template>
  <div>
    <v-app-bar app :color="bg" hide-on-scroll elevate-on-scroll>
      <v-app-bar-nav-icon
        dark
        @click="drawer = true"
        class="d-flex d-md-none"
      ></v-app-bar-nav-icon>
      <v-layout
        class="d-md-flex d-none"
        :class="
          $vuetify.breakpoint.lgAndUp
            ? 'justify-center'
            : 'justify-space-around'
        "
      >
        <v-btn
          v-for="item in getItems"
          :key="item.name"
          text
          dark
          @click="$vuetify.goTo('#' + item.goTo, options)"
          :class="[{ menu_btn: $vuetify.breakpoint.lgAndUp }]"
          style="font-weight: bold"
        >
          {{ $t(item.name) }}
        </v-btn>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in getItems" :key="item.value">
            <v-list-item-title @click="tab = index">
              <v-btn
                text
                @click="$vuetify.goTo('#' + item.goTo, options)"
                style="font-weight: bold"
              >
                {{ $t(item.name) }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "AppNavigation",
  data() {
    return {
      duration: 1000,
      offset: 5,
      drawer: false,
      bg: "transparent",
      itemsMenuMobile: [
        {
          name: "home",
          goTo: "home",
          content: true,
        },
        {
          name: "gallery3D",
          goTo: "homeGallery",
          content:
            this.$exist_gallery_livingroom || this.$exist_gallery_kitchen,
        },
        {
          name: "virtualTour",
          goTo: "homeTourVirtual",
          content: this.$video_360,
        },
        {
          name: "film3D",
          goTo: "homeFilm3D",
          content: this.$video_3dfilm,
        },
        {
          name: "catalog",
          goTo: "homeCatalog",
          content: this.$exist_catalog,
        },
        {
          name: "customizationApp",
          goTo: "homeSpaces",
          content: this.$spaces,
        },
        {
          name: "contact",
          goTo: "homecontact",
          content: true,
        },
      ],
    };
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
      };
    },
    getItems: function () {
      let items = [];
      this.itemsMenuMobile.forEach((element) => {
        if (element.content) items.push(element);
      });
      return items;
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = "rgba(0, 0, 0, 0.5)";
      } else {
        this.bg = "transparent";
      }
    },
  },
};
</script>
<style scoped>
.theme--light.v-card {
  background-color: transparent !important;
}

.menu_btn {
  margin: 0px 2rem;
}
</style>
