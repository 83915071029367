<template>
  <div
    id="homeGallery"
    class="section_background"
    :style="$vuetify.breakpoint.mdAndUp ? 'min-height: 500px' : ''"
  >
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12">
          <header-section
            :title="$t('gallery3D')"
            :subtitle="$t('gallerySubtitle')"
          ></header-section>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'flowRoot' : ''">
        <more-image
          v-if="this.$exist_gallery_livingroom"
          :md="this.$gallery_grid_livingroom"
          height="750"
          :image_prop="this.$image_livingroom"
          :title="$t('websiteImageBox1')"
          :items="this.$gallery_images_livingroom"
        >
        </more-image>
        <more-image
          v-if="this.$exist_gallery_kitchen"
          :md="this.$gallery_grid_kitchen"
          height="363"
          :image_prop="this.$image_kitchen"
          :title="$t('websiteImageBox2')"
          :items="this.$gallery_images_kitchen"
        >
        </more-image>
        <more-image
          v-if="this.$exist_gallery_bath1"
          :md="this.$gallery_grid_bath1"
          height="363"
          :image_prop="this.$image_bath1"
          :title="$t('websiteImageBox3')"
          :items="this.$gallery_images_bath1"
        >
        </more-image>
        <more-image
          v-if="this.$exist_gallery_bath2"
          :md="this.$gallery_grid_bath2"
          height="363"
          :image_prop="this.$image_bath2"
          :title="$t('websiteImageBox4')"
          :items="this.$gallery_images_bath2"
        >
        </more-image>
        <more-image
          v-if="this.$exist_gallery_bedroom"
          :md="this.$gallery_grid_bedroom"
          height="363"
          :image_prop="this.$image_bedroom"
          :title="$t('websiteImageBox5')"
          :items="this.$gallery_images_bedroom"
        >
        </more-image>
        <more-image
          v-if="this.$exist_gallery_others"
          :md="this.$gallery_grid_others"
          height="363"
          :image_prop="this.$image_others"
          :title="$t('websiteImageBox6')"
          :items="this.$gallery_images_others"
        >
        </more-image>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MoreImage from "../components/MoreImage";
import HeaderSection from "../components/HeaderSection";

export default {
  name: "HomeGallery",
  components: {
    MoreImage,
    HeaderSection,
  },
};
</script>

<style scoped>
.flowRoot {
  display: flow-root !important;
}
</style>
