import { render, staticRenderFns } from "./HomeSpaces.vue?vue&type=template&id=1cd45577&scoped=true&"
import script from "./HomeSpaces.vue?vue&type=script&lang=js&"
export * from "./HomeSpaces.vue?vue&type=script&lang=js&"
import style0 from "./HomeSpaces.vue?vue&type=style&index=0&id=1cd45577&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd45577",
  null
  
)

export default component.exports