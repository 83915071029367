<template>
  <div>
    <home-slide />
    <home-intro />
    <home-gallery
      v-if="this.$exist_gallery_livingroom || this.$exist_gallery_kitchen"
    />
    <home-tour-virtual v-if="this.$video_360" />
    <home-3DFilm v-if="this.$video_3dfilm" />
    <home-catalog v-if="this.$exist_catalog" />
    <home-spaces v-if="this.$spaces" />
    <home-contact />
  </div>
</template>

<script>
import HomeSlide from "../components/HomeSlide";
import HomeIntro from "../components/HomeIntro";
import HomeGallery from "../components/HomeGallery";
import HomeTourVirtual from "../components/HomeTourVirtual";
import Home3DFilm from "../components/Home3DFilm";
import HomeCatalog from "../components/HomeCatalog";
import HomeSpaces from "../components/HomeSpaces";
import HomeContact from "../components/HomeContact";

export default {
  name: "HomeView",

  components: {
    HomeSlide,
    HomeIntro,
    HomeGallery,
    HomeTourVirtual,
    Home3DFilm,
    HomeCatalog,
    HomeSpaces,
    HomeContact,
  },
};
</script>

<style scoped></style>
