import Vue from "vue";

/*TITLE*/
document.title = "Ponte Burgo | Porcelanosa PARTNERS";

/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*SLIDESHOW*/
Vue.prototype.$title = true;
Vue.prototype.$subtitle = false;
Vue.prototype.$exist_logotype = false;

/*GALLERY*/
Vue.prototype.$exist_gallery_livingroom = true;
Vue.prototype.$exist_gallery_kitchen = false;
Vue.prototype.$exist_gallery_bath1 = true;
Vue.prototype.$exist_gallery_bath2 = true;
Vue.prototype.$exist_gallery_bedroom = false;
Vue.prototype.$exist_gallery_others = false;

Vue.prototype.$image_livingroom = "linkfloor-feudal-beige-228x152x05--20230328110359.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-principal-urban-acero-nature-decorado-limit-urban-acero-nature--20230126120110.jpg";
Vue.prototype.$image_bath2 = "banyo-auxiliar-bottega-acero-decorado-bottega-spiga--20230126120124.jpg";
Vue.prototype.$image_bedroom = "";
Vue.prototype.$image_others = "";

Vue.prototype.$gallery_images_livingroom = [
  {
    src: "linkfloor-feudal-beige-228x152x05--20230328110359.jpg",
  },
  {
    src: "linkfloor-feudal-brown-228x152x05--20230328110354.jpg",
  },
  {
    src: "linkfloor-feudal-grey-228x152x05--20230328110350.jpg",
  },
  {
    src: "linkfloor-feudal-natural-228x152x05--20230328110345.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-principal-urban-acero-nature-decorado-limit-urban-acero-nature--20230126120110.jpg",
  },
  {
    src: "banyo-principal-urban-natural-nature-decoradolimit-urban-natural-nature--20230126120101.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-auxiliar-bottega-acero-decorado-bottega-spiga--20230126120124.jpg",
  },
  {
    src: "banyo-auxiliar-bottega-caliza-decorado-bottega-spiga--20230126120117.jpg",
  },
];

Vue.prototype.$gallery_images_bedroom = [
];

Vue.prototype.$gallery_images_others = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_livingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_bedroom = "6";
Vue.prototype.$gallery_grid_others = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$video_360 = "";

/*VIDEO 3DFILM*/
Vue.prototype.$video_3dfilm = "";

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/ponteburgo/?e=iframe";

/*CATALOG*/
Vue.prototype.$exist_catalog = true;

/*PROMOTION LOGO*/
Vue.prototype.$exist_promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$exist_promoter_logo = true;

/*PROMOTER ADDRESS*/
Vue.prototype.$promoter_address  = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=pontevedra",
    text: "Pontevedra",
  },
];

/*SHOWROOM ADDRESS*/
Vue.prototype.$showroom_address  = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=pontevedra",
    text: "Pontevedra",
  },
];

/*CONTACT*/
Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:986 871 877",
    text: "986 871 877",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@grupopirsa.com",
    text: "info@grupopirsa.com",
  },
];

