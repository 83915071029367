<template>
  <div id="homeCatalog">
    <v-container fluid class="section">
      <v-row>
        <v-col cols="12" md="6">
          <header-section
            :title="$t('catalog')"
            :subtitle="$t('catalogSubtitle')"
          ></header-section>
          <v-btn
            href="/resources/catalog.pdf"
            outlined
            rounded
            download
            class="font-weight-bold"
          >
            {{ $t("download") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            :class="$vuetify.breakpoint.mdAndDown ? 'mx-auto' : ''"
            :src="this.getImgCatalog"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderSection from "../components/HeaderSection";

export default {
  name: "HomeCatalog",
  components: {
    HeaderSection,
  },
  computed: {
    getImgCatalog: function () {
      return this.$typeProject == "SELECT"
        ? require("/public/porce_catalog_select.jpg")
        : require("/public/porce_catalog_partners.jpg");
    },
  },
};
</script>

<style scoped></style>
